.page-not-found{

    .newStore{
        justify-content: center;
        position: absolute;
        right: 0;
        left: 0;
        height: 100%;
        align-items: center;
        top: 0;
        margin: 0px 0px 0px 240px !important;
    }
    h4{
        font-size: 28px;
        color: #222945b5;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        text-align: center;
    }
}