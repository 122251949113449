.d-f {
  display: flex;
}
.a-i {
  align-items: center;
}
.jC-c {
  justify-content: center;
}
.jC-r {
  justify-content: right;
}
.jC-l {
  justify-content: left;
}
.jC-sB {
  justify-content: space-between;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-25 {
  padding: 25px;
}
.p-30 {
  padding: 30px;
}
.p-35 {
  padding: 35px;
}
.p-40 {
  padding: 40px;
}
.w-100 {
  width: 100%;
}

.cke_inner {
  z-index: 999999 !important;
}
.bookDetails-new-modal {
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 100%;
    width: 40%;
    height: 100vh;
    display: flex;
    align-items: center;
    margin: 0rem auto;
  }
}
@media only screen and (min-width: 0px) and (max-width: 1080px) {
  .col-md-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.Book-details {
  .details-page-loading {
    position: absolute;
    background: #ffffffb5;
    right: 0;
    left: 0;
    height: 100%;
    z-index: 111;
    top: 0;
  }

  .summary-counts {
    position: absolute;
    left: 0px;
    top: 0px;
    background: #524b67;
    border-radius: 100px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f7f7f7;
    font-size: 12px;
  }

  .summarry-card-button {
    display: flex;
    align-items: center;
    padding-top: 15px;
    white-space: normal;
    overflow: auto;
  }
  .HomePage_static_description {
    font-size: 14px;
  }
  .updates-button {
    display: flex;
    align-items: center;
    padding: 10px 0px 10px 0px;
    Button {
      font-size: 13px;
    }
  }
  .book-details-section {
    width: 100%;
    padding: 20px 20px 20px 20px !important;
    background: #fcfcfc;
    position: relative;
  }
  .image-update {
    position: absolute;
    top: -8px;
    background: #212945;
    padding: 3px;
    border-radius: 100px;
    z-index: 111;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      padding: 3px;
      color: #fcfcfc;
    }
  }
  .audio-update {
    background: #fcfcfc;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    border: 1px solid #d4d4d4 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .summary-view-button {
    padding: 4px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  .summary-update-button {
    background: #e3e3e3;
    padding: 4px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 13px;
    border: 1px solid #eee !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #444;
  }
  .summaries-list {
    background: #f3f2ee;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
  }

  .List-Book-card {
    width: 100%;
    border-right: 1px solid #eee;
    .List-Book {
      display: flex;
      width: 100%;
      font-size: 14px;
      padding-bottom: 10px;
      align-items: center;
    }
    .Book-list-heading {
      font-weight: 600;
      padding-right: 10px;
      white-space: nowrap;
    }
    .Book-list-text {
      font-weight: 500;
      padding-right: 10px;
      width: 100%;
    }
    .List-Book-subcard {
      display: flex;
      padding-right: 20px;
    }
  }
}
.accordion {
  margin-bottom: 10px;
}
.accordion > .card > .card-header {
  padding: 10px;
  text-align: left;
}
.rhap_header {
  white-space: normal;
}
.rhap_repeat-button {
  display: none !important;
}
.rhap_container {
  background-color: #f3f2ed !important;
  box-shadow: none !important;
}
.rhap_footer {
  cursor: pointer;
}

.view-summary-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 700px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
}
.addNewSummary-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 700px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
}

.image-update-modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 550px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
  .image-update-button {
    width: 100%;
  }
  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    border: 1px solid #eee;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 111;
    cursor: pointer;
  }
}

.ckEditorWarm {
  display: none !important;
}
.modal-header {
  display: flex;
  align-items: center !important;
}
.modal-title {
  font-size: 1rem !important;
}

.plyr__progress {
  input {
    border: none !important;
  }
  input:focus {
    border: none !important;
  }
}
.plyr__volume {
  input {
    border: none !important;
  }
  input:focus {
    border: none !important;
  }
}

.plyr--audio {
  [data-plyr="mute"] {
    background-color: transparent !important;
  }
  // [data-plyr=rewind]{
  //   display: none !important;
  // }
  // [data-plyr=fast-forward]{
  //   display: none !important;
  // }
  [data-plyr="play"],
  [data-plyr="rewind"],
  [data-plyr="fast-forward"] {
    background-color: transparent !important;
    border-radius: 100px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 0 5px;
    svg {
      color: #2c2245 !important;
      width: 1rem !important;
      height: 1rem !important;
    }
  }

  svg {
    color: #2c2245 !important;
    width: 1rem !important;
    height: 1rem !important;
  }

  .plyr__volume input[type="range"] {
    color: #2c2245 !important;
  }
  .plyr__progress input[type="range"] {
    color: #2c2245 !important;
  }
}

.plyr--video {
  [data-plyr="mute"] {
    background-color: transparent !important;
  }
  // [data-plyr=rewind]{
  //   display: none !important;
  // }
  // [data-plyr=fast-forward]{
  //   display: none !important;
  // }
  [data-plyr="play"],
  [data-plyr="rewind"],
  [data-plyr="fast-forward"] {
    background-color: transparent !important;
    border-radius: 100px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    svg {
      color: #eee !important;
      width: 2rem !important;
      height: 2rem !important;
    }
  }

  svg {
    color: #eee !important;
    width: 2rem !important;
    height: 2rem !important;
    padding: 5px !important;
  }
  .plyr__time {
    color: #eee !important;
  }

  .plyr__volume input[type="range"] {
    color: #eee !important;
  }
  .plyr__progress input[type="range"] {
    color: #eee !important;
  }
}

.plyr--audio .plyr__controls {
  border-radius: 10px !important;
  background: #f3f2ee !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.plyr--audio .plyr__progress__buffer {
  color: #595959 !important;
}

// .plyr--audio .plyr__progress__buffer{
//   color: #595959 !important;
// }
.cms-player-width {
  width: 100% !important;
}

.summary-dropdown {
  li {
    a {
      text-decoration: none !important;
    }
  }
}

.progress-media:before {
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.summary-media-section {
  .dzu-inputLabel {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  .dzu-submitButtonContainer {
    margin: 10px 0;
  }
  .dzu-previewContainer {
    padding: 10px !important;
  }
  .dzu-previewFileName {
    color: #2584ff !important;
    font-size: 10px !important;
  }

  .dzu-submitButton {
    width: 100%;
    background: #2c2245 !important;
  }
}
