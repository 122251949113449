.task-Modal {
  display: flex !important;
  align-items: center;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 1080px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fbfbfb;
    padding: 1.4rem;
  }
  .table td,
  .table th {
    vertical-align: middle !important;
  }

  .table td,
  .table th {
    vertical-align: middle !important;
    padding: 8px !important;
    border: 1px solid #dee2e654;
    color: #5c5e5f;
    font-size: 14px;
    background: #f8f8f7;
  }
  th:first-child {
    border-left: none !important;
    width: 10px;
  }
}
.coloredButtonClose {
  background-color: #d26464 !important;
  color: #fcfcfc !important;
  border: 1px solid #d26464 !important;
}
.table_search_task:focus {
  border: 1px solid #2129457a !important;
}
.table_search_task {
  box-shadow: none !important;
}
