.css-1u9des2-indicatorSeparator{
  display: none !important;
}

.modal-content{
  border-radius: 10px !important;
  box-shadow: 0 2px 8px rgba(0,0,0,.15);
  border: none !important;
}
.modal-body{
  border-radius: 10px !important;
  box-shadow: 0 2px 8px rgba(0,0,0,.15);
}