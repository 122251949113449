.cke_inner {
  z-index: 999999 !important;
}
.Category-new-modal {
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 100%;
    width: 40%;
    height: 100vh;
    display: flex;
    align-items: center;
    margin: 0rem auto;
  }
}
.manage-buttons {
  display: flex;
  align-items: center;
}
.category-page {
  .image-update {
    position: absolute;
    top: 0;
    background: #fcfcfc;
    left: 0;
    border-radius: 100px;
    z-index: 111;
    cursor: pointer;
    svg {
      font-size: 16px;
    }
  }
}

.category-select-language{
  input{
    border: none !important;
  }
  input:focus{
    border: none !important;
  }
}
