.task-Modal {
  display: flex !important;
  align-items: center;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 1240px !important;
    width: 98% !important;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fbfbfb;
    padding: 1.4rem;
  }
  .table td,
  .table th {
    vertical-align: middle !important;
  }

  .table td,
  .table th {
    vertical-align: middle !important;
    padding: 8px !important;
    border: 1px solid #dee2e654;
    color: #5c5e5f;
    font-size: 14px;
    background: #f8f8f7;
  }
  th:first-child {
    border-left: none !important;
    width: 10px;
  }
}

.view-Task-Modal {
  display: flex !important;
  align-items: center;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 1040px !important;
    width: 98% !important;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-content {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px !important;
    border: 1px solid #1c162e2e !important;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fbfbfb;
    padding: 1.4rem;
  }
  .table td,
  .table th {
    vertical-align: middle !important;
  }

  .table td,
  .table th {
    vertical-align: middle !important;
    padding: 8px !important;
    border: 1px solid #dee2e654;
    color: #5c5e5f;
    font-size: 14px;
    background: #f8f8f7;
  }
  th:first-child {
    border-left: none !important;
    width: 10px;
  }
}

.select-options {
  span {
    display: none !important;
  }
  input{
    border: none !important;
  }
  input:focus{
    border: none !important;
  }
}
