.LiT-Body {
  .supportTag .bg__color {
    padding: 10px;
    color: #bb6b11;
    text-align: left;
    display: flex;
    align-items: center;
  }
  .supportTag a:hover {
    text-transform: none;
    outline: none;
    border: none;
    text-decoration: none;
  }
  .ss_page-body {
    margin-left: 220px;
    padding-top: 75px;
    padding-bottom: 20px;
  }
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .ss_page-body {
      padding-left: 0;
    }
    .ss_page-footer {
      margin-left: 0 !important;
    }
  }
  .mdlPopup {
    position: absolute;
    z-index: 9999;
    width: 40%;
    right: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .ppTxt {
    text-align: center;
  }
  .pphdng {
    text-align: center;
    padding: 20px 0px 20px 0px;
  }
  .Okbtn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ppSty {
    padding: 5px;
    line-height: 1.4;
    font-size: 14px;
    margin-bottom: 1px;
  }

  .modal-dialog {
    max-width: 45% !important;
  }
  .ss_page-footer {
    position: fixed;
    right: 0px;
    bottom: 0px;
    text-align: center;
    background: #fff;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    padding-right: 20px;
    border-top: 1px solid #e9e9e9 !important;
    font-size: 14px;
    margin-left: 220px;
    z-index: 111;
  }
}

.redirectCenter {
  text-align: center;
}

.cke_toolbar {
  float: unset !important;
}
.cke_button__save,
.cke_button__newpage,
.cke_button__preview,
.cke_button__image,
.cke_button__table,
.cke_button__horizontalrule,
.cke_button__smiley,
.cke_button__specialchar,
.cke_button__link,
.cke_button__unlink,
.cke_button__anchor,
.cke_button__numberedlist,
.cke_button__outdent,
.cke_button__indent,
.cke_button__blockquote,
.cke_button__creatediv,
.cke_button__strike,
.cke_button__removeformat {
  display: none !important;
}
.cke_toolbar_break {
  clear: none !important;
}
