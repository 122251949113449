.language-select{
  .Language-select-card{
    display: flex;
    align-items: center;
    width: 100%;
  }
  .language-box{
    border: 1px solid #eee;
    text-align: center;
    padding: 10px;
    cursor: pointer;
  }
  .language-box:hover{
    border: 1px solid #91d0ff;
  }
.loginRow{
  justify-content:center;
  position: absolute;
  right: 0;
  left: 0;
  height: 100%;
  align-items: center;
}
.rowBox{
  width:600px;
  max-width:100%;
  border: 1px solid #e9e9e9!important;
  background:#fff;
  padding:30px;
  border-radius: 5px;
}
.form-control{
  background: #fbfbfb !important;
  height: 55px !important;
  border: 1px solid #eee !important;
}
.storeCard {
  padding: 30px;
  background-color: #fff;
}
.storeCardB {
  padding: 20px;
  background-color: #fff;
}
.storeCardH {
  padding: 0px;
  background-color: #fff;
}
.loginHeader{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;;
}
.Button{
  margin-bottom: 20px;
  width:100%;
}
.form-group{
  text-align:left;
}
.errorHeadng{
  text-align: center;
  color: #bb2525;
}
.lgnSpin{
  width: 1.5rem;
  height: 1.5rem;
}
.lgnSpinB{
  display: flex;
  align-items: center;
  justify-content: center;
}

.seperator {
  padding: 5px;
}

.otp {
  align-items: center;
  justify-content: center;
}

.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.inputStyle:hover{
  border: 2px solid #5eb2d9;
}
.inputStyle:focus{
  border: 2px solid #5eb2d9;
}
.inputStyle:active{
  border: 2px solid #5eb2d9;
}
.otpCon{
  margin-top: 30px;
  width: 180px;
}
.resendBtn{
  width: 200px;
  width: auto;
  background: #fff0;
  color: #055894;
  border: none;
}
.resendBtn:hover{
  width: 200px;
  width: auto;
  background: #fff0;
  color: #055894;
  border: none;
}
.lableLeft{
  font-size: 18px;
  margin-bottom: 20px;
}
.btnToRD{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.textMsgV{
  text-align: center;
}

}