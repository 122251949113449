.cke_inner {
  z-index: 999999 !important;
}
.bhref {
  white-space: normal;
  width: 350px;
  display: block;
  color: rgb(0, 123, 255);
  cursor: pointer;
}

.bhref:hover {
  text-decoration: underline;
}

.manage-buttons {
  display: flex;
  align-items: center;
}

.book-Modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 700px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
}

.new-book-Cover {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 550px;
    width: 98%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
}

.new-book-item {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 1140px;
    width: 98%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
}

.table-update {
  position: absolute;
  background: #ffffffb5;
  right: 0;
  left: 0;
  height: 100%;
  z-index: 111;
}

.books-page {
  .image-update {
    position: absolute;
    top: 0;
    background: #fcfcfc;
    left: 0;
    border-radius: 100px;
    z-index: 111;
    cursor: pointer;
    svg {
      font-size: 16px;
    }
  }
}
.search-in-table {
  position: absolute;
  background: #ffffff80;
  right: 0;
  left: 0;
  height: 100%;
  z-index: 999;
  top: 0;
}
.booksTag {
  position: relative;
  cursor: pointer;
  background: #2c2245cf !important;
}
.booksTagE {
  position: relative;
  cursor: pointer;
  background: tomato !important;
}
.booksTagsDelete {
  position: absolute;
  right: -5px;
  top: -10px;
  color: red;
  background-color: #fcfcfc;
  border: 1px solid #eee;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    font-size: 16px;
  }
}
.coloredButton {
  background-color: #212945 !important;
  color: #fcfcfc !important;
  border: 1px solid #212945 !important;
}
button {
  box-shadow: none !important;
  outline: none !important;
}
coloredButton:hover {
  background: #212945d6 !important;
}

.modal-backdrop {
  background-color: #00000061 !important;
}

.viewBookItemsModal {
  display: flex !important;
  align-items: center;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 1280px;
    width: 190%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 0rem;
  }
  .table td,
  .table th {
    vertical-align: middle !important;
  }

  .table td,
  .table th {
    vertical-align: middle !important;
    padding: 8px !important;
    border: 1px solid #dee2e654;
    color: #5c5e5f;
    font-size: 13px;
    background: #f8f8f7;
  }
  th:first-child {
    border-left: none !important;
    width: 10px;
  }
  th:first-child {
    border-left: none !important;
    padding: 15px !important;
  }
  td:first-child {
    border-left: none !important;
    padding: 15px !important;
  }
}

.statesModal {
  display: flex !important;
  align-items: center;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 450px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-header {
    background: #f3f2ee;
  }
  .modal-body {
    background: #fafafa;
    padding: 1.3rem;
  }
  .state-style {
    display: block;
    font-size: 1rem;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
}

.items-update-icon {
  font-size: 1.8rem;
  background: #212945;
  padding: 5px;
  border-radius: 2px;
  color: #fcfcfc;
  cursor: pointer;
}
label {
  display: flex !important;
}

// .select-options{
//   cursor: pointer;
//   .css-13cymwt-control{
//     cursor: pointer;
//   }
//   .css-t3ipsp-control:focus{
//     box-shadow: none !important;
//     border-color: hsl(0, 0%, 80%) !important;
//   }
//   .css-t3ipsp-control:hover{
//     box-shadow: none !important;
//     border-color: hsl(0, 0%, 80%) !important;
//   }
//   .css-t3ipsp-control{
//     box-shadow: none !important;
//     border-color: hsl(0, 0%, 80%) !important;
//     cursor: pointer;
//   }
// }
.select-field {
  input:focus {
    border: none !important;
  }
  input{
    border: none !important;
  }
}

